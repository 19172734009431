<template>

    <div class="notice-list">
      <TopBack :title="$t('home.noticeList')"></TopBack>
      <div class="content" v-if="list.length>0">
        <div v-for="(item,i) in list" :key="i">
          <p v-if="locale==='en-US'">{{toHHmmss(item.createTime,-5)}} </p>
          <p v-else>{{toHHmmss(item.createTime,+8)}} </p>
          <div class="card" @click="getDetail(item)">
            <div class="title">{{item.title}}</div>
            <div class="info">{{item.content}}</div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <img src="../assets/images/no_data.png" alt=""/>
      </div>
    </div>

</template>
<script setup>
import TopBack from './components/TopBack'
import {toHHmmss} from '../utils/decimalTool'
import {getCurrentInstance, onBeforeMount, ref} from 'vue'
import {getNoticeList} from "../api";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
const list=ref([{},{},{}])
const { locale } = useI18n()
const router=useRouter()
const { $t } = getCurrentInstance().proxy;
// const loading=ref(false)
onBeforeMount(()=>{
  getHttp()
})

const getHttp=()=>{
  getNoticeList().then(res=>{
    list.value=res.data.list
  })
}
const getDetail=(item)=>{
  sessionStorage.setItem('noticeContent',JSON.stringify(item))
  router.push('/noticeDetails')
}
</script>
<style scoped lang="less">
.notice-list{
  padding:0 18px;
  min-height: 100vh;
  .content{
    p{
      font-weight: 500;
      font-size: 12px;
      color: rgba(126, 141, 149, 0.8);
      margin-bottom: 20px;
    }
    .card{
      background: #232A2E;
      border-radius: 5px;
      padding: 24px 18px;
      margin-bottom: 36px;

      .title{
        font-weight: 500;
        font-size: 17px;
        color: #6FFEE8;
        text-align: left;
        margin-bottom: 20px;
      }
      .info{
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }
}
.no-data{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  img{
    width: 176px;
    height: 258px;
  }
}
</style>
